import { createSlice } from "@reduxjs/toolkit"

export const bookFlowInitialState = {
  appointmentType: "",
  step: 0,
  fromSpecialistsPage: false,
  checkout: {
    category: null,
    service: null,
    doctor: null,
    selectedDoctor: null,
    location: null,
    timeAndDate: null,
    patientInformation: null,
    comment: null,
    buttonContinue: false,
    selectedSlot: null,
  },
  insurance: null,
  userTryToSubmit: false,
  availabilities: [],
  categoryAvailabilities: [],
  clinissians: [],
  modalThankYouOn: false,
  availabilitiesLoader: false,
  email: "",
  code: "",
}

export const bookFlowSlice = createSlice({
  name: "bookFlow",
  initialState: bookFlowInitialState,
  reducers: {
    setAppointmentType: (state, { payload }) => {
      state.appointmentType = payload
    },
    setStep: (state, { payload }) => {
      state.step = payload
    },
    setServiceCategory: (state, { payload }) => {
      state.serviceCategory = payload
    },
    setSelectedDoctor: (state, { payload }) => {
      state.checkout.selectedDoctor = payload
    },
    setLocation: (state, { payload }) => {
      state.location = payload
    },
    setCheckoutValuesChanges: (state, { payload }) => {
      console.log("setCheckoutValuesChanges payload", payload?.value)
      state.checkout = {
        ...state.checkout,
        [payload.name]: payload.value,
        ...payload.clearFields,
        buttonContinue: payload.button,
      }
    },
    setButtonContinueToggle: (state, { payload }) => {
      state.checkout.buttonContinue = payload
    },
    setEmail: (state, { payload }) => {
      state.email = payload
    },
    setModalToggle: (state, { payload }) => {
      state.modalThankYouOn = payload
    },
    setCommentChange: (state, { payload }) => {
      console.log("setCommentChange payload", payload)
      state.checkout.comment = payload
    },
    resetToInitialState: state => {
      state = Object.assign(state, bookFlowInitialState)
    },
    setFromSpecialistsPage: (state, { payload }) => {
      state.fromSpecialistsPage = payload
    },
    setAvailabilities: (state, { payload }) => {
      state.availabilities = payload
    },
    setCategoryAvailabilities: (state, { payload }) => {
      state.categoryAvailabilities = payload
    },
    setAvailabilitiesLoader: (state, { payload }) => {
      state.availabilitiesLoader = payload
    },
    setClinissians: (state, { payload }) => {
      state.clinissians = payload
    },
    setInsurance: (state, { payload }) => {
      state.insurance = payload
    },
    setUserTryToSubmit: (state, { payload }) => {
      state.userTryToSubmit = payload
    },
  },
})

export const {
  setAppointmentType,
  setStep,
  setServiceCategory,
  setSelectedDoctor,
  setLocation,
  setButtonContinueToggle,
  setCheckoutValuesChanges,
  setModalToggle,
  setEmail,
  setCommentChange,
  resetToInitialState,
  setFromSpecialistsPage,
  setAvailabilities,
  setCategoryAvailabilities,
  setAvailabilitiesLoader,
  setClinissians,
  setInsurance,
  setUserTryToSubmit,
} = bookFlowSlice.actions

export default bookFlowSlice.reducer
