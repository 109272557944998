import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  contactUsScrollToForm: false,
}

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setContactUseScroll: (state, { payload }) => {
      state.contactUsScrollToForm = payload
    },
  },
})

export const { setContactUseScroll } = rootSlice.actions

export default rootSlice.reducer
