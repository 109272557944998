/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import "./assets/styles/global.scss"
// import {
//   getAllCategories,
//   getAllServices,
// } from "./src/services/bookingPharmacyServices"
// import {
//   // resetToInitialState,
//   setCategories,
//   setServices,
// } from "./src/store/features/pharmacyBookFlow/pharmacyBookFlow"

// import { store } from "./src/store/store"

export const onClientEntry = () => {
  const isAppointmentPage = window.location.pathname.includes(
    "/book-an-appointment"
  )

  if (!isAppointmentPage) {
    localStorage.removeItem("bookFlowState")
  }

  const isBookingPharmacyPage =
    window?.location.pathname.includes("/booking-pharmacy")

  if (!isBookingPharmacyPage) {
    localStorage.removeItem("pharmacyBookFlow")
  }
}

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   window.previousPath = prevLocation ? prevLocation.pathname : null
//   if (
//     location.pathname === "/" ||
//     location.pathname === "/patient-information/"
//   ) {
//     window.scrollTo(0, 0)
//   }
// }

// export const onPrefetchPathname = async ({ pathname }) => {
//   if (pathname.includes("/booking-pharmacy")) {
//     const currentCategories = store.getState().pharmacyBookFlow.categories
//     const currentServices = store.getState().pharmacyBookFlow.services
//     if (currentCategories || currentServices) return
//     const [categories, services] = await Promise.all([
//       getAllCategories(),
//       getAllServices(),
//     ]).catch(err => console.log(err))

//     if (categories) {
//       store.dispatch(setCategories(categories.data))
//     }
//     if (services) {
//       store.dispatch(setServices(services.data))
//     }
//   }
// }

export { default as wrapRootElement } from "./src/components/ReduxWrapper"
